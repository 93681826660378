<template>
    <div class="header-menu-main" >
        <div class="menu-components">
            <div class="company-logo">
                <a style="visibility: hidden;" href="/"><img src="../assets/img/company-logo-gold.png" alt=""></a>
            </div>
            
            <div class="d-flex flex-column">
                <div class="company-logo mx-auto">
                    <a href="/"><img src="../assets/img/company-logo-gold.png" style="width: 100px;" alt=""></a>
                </div>
                <div class="header-anchors">
                    <a href="/">О нас</a>
                    <a href='/hotels'>Отели и номера</a>
                    <a href="#contacts">Контакты</a>
                    <!-- <a href="#" onclick="alert('Скоро появится на сайте!')">Авиабилеты</a> -->
                </div>
            </div>
            
            <div class="wrapper" >
                <div class="head">
                    <div class="container">
                        <div class="header__body">
                            <div class="header__logo">
                                <a href="/"><img src="../assets/img/company-logo-gold.png" alt=""></a>
                            </div>
                            <div class="header__burger" @click="toggleMenu">
                                <span></span>
                                <span></span>
                                <span class="burger-little-bar"></span>
                            </div>
                            <nav class="header__menu" :class="{ active: isActive }">
                                <ul class='header__list'>
                                    <li @click="toggleMenu">
                                        <a href="/" class="header__link">О нас</a>
                                    </li>
                                    <li @click="toggleMenu">
                                        <a href='/hotels' class="header__link">Отели и номера</a>
                                    </li>
                                    <li @click="toggleMenu">
                                        <a href="#contacts" class="header__link">Контакты</a>
                                    </li>
                                    <!-- <li onclick="alert('Скоро появится на сайте!')">
                                        <a href="#" class="header__link">Авиабилеты</a>
                                    </li> -->
                                    <li @click="toggleMenu">
                                        <div class="d-flex flex-column">
                                            <p style="color: #fff;" class="mb-1 text-end">Личный кабинет</p>
                                            <div class="d-flex">
                                                <a v-if="$store.state.user == true && $store.state.user_info.role=='user'" class="user-logo" href="/lk">
                                                    <img src="../assets/img/guest_lk_white.png" alt="">
                                                </a>
                                                <a v-if="$store.state.user == false" class="user-logo" href="/auth?role=user">
                                                    <img src="../assets/img/guest_lk_white.png" alt="">
                                                </a>
                                                <a v-if="$store.state.user == true && $store.state.user_info.role=='agent'" href="/lk_agent" class="ms-2 user-logo">
                                                    <img src="../assets/img/agent_lk_white.png" alt="">
                                                </a>
                                                <a v-if="$store.state.user == false" href="/auth?role=agent" class="ms-2 user-logo">
                                                    <img src="../assets/img/agent_lk_white.png" alt="">
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="user-logo">
                <div class="d-flex flex-column">
                    <p style="color: #fff;" class="my-2 text-center">Личный кабинет</p>
                    <div class="d-flex">
                        <a v-if="$store.state.user == true && $store.state.user_info.role=='user'" href="/lk">
                            <img src="../assets/img/guest_lk_white.png" alt="">
                        </a>
                        <a v-if="$store.state.user == false" href="/auth?role=user">
                            <img src="../assets/img/guest_lk_white.png" alt="">
                        </a>
                        <a v-if="$store.state.user == true && $store.state.user_info.role=='agent'" href="/lk_agent" class="ms-2">
                            <img src="../assets/img/agent_lk_white.png" alt="">
                        </a>
                        <a v-if="$store.state.user == false" href="/auth?role=agent" class="ms-2">
                            <img src="../assets/img/agent_lk_white.png" alt="">
                        </a>
                    </div>
                    
                </div>
                
            </div>
        </div>
    </div>
    <div class="header-menu-user"
        v-if="$route.path !== '/'">
        <div class="menu-components">
            <div class="company-logo">
                <a href="/"><img src="../assets/img/company-logo-gold.png" alt=""></a>
            </div>
            <div class="header-anchors">
                <a href="/">О нас</a>
                <a href='/hotels'>Отели и номера</a>
                <a href="#contacts">Контакты</a>
                <!-- <a href="#" onclick="alert('Скоро появится на сайте!')">Авиабилеты</a> -->
            </div>
            <div class="wrapper">
                <div class="head">
                    <div class="container">
                        <div class="header__body">
                            <div class="header__logo">
                                <a href="/"><img src="../assets/img/company-logo-gold.png" alt=""></a>
                            </div>
                            <div class="header__burger" @click="toggleMenu">
                                <span></span>
                                <span></span>
                                <span class="burger-little-bar"></span>
                            </div>
                            <nav class="header__menu" :class="{ active: isActive }">
                                <ul class='header__list'>
                                    <li @click="toggleMenu">
                                        <a href="/" class="header__link">О нас</a>
                                    </li>
                                    <li @click="toggleMenu">
                                        <a href='/hotels' class="header__link">Отели и номера</a>
                                    </li>
                                    <li @click="toggleMenu">
                                        <a href="#contacts" class="header__link">Контакты</a>
                                    </li>
                                    <!-- <li onclick="alert('Скоро появится на сайте!')">
                                        <a href="#" class="header__link">Авиабилеты</a>
                                    </li> -->
                                    <li @click="toggleMenu">
                                        <div class="d-flex flex-column">
                                            <p style="color: #343434;" class="mb-1 text-end">Личный кабинет</p>
                                            <div class="d-flex">
                                                <a v-if="$store.state.user == true && $store.state.user_info.role=='user'" class="user-logo" href="/lk">
                                                    <img src="../assets/img/guest_lk_black.png" alt="">
                                                </a>
                                                <a v-if="$store.state.user == false" class="user-logo" href="/auth?role=user">
                                                    <img src="../assets/img/guest_lk_black.png" alt="">
                                                </a>
                                                <a v-if="$store.state.user == true && $store.state.user_info.role=='agent'" href="/lk_agent" class="ms-2 user-logo">
                                                    <img src="../assets/img/agent_lk_black.png" alt="">
                                                </a>
                                                <a v-if="$store.state.user == false" href="/auth?role=agent" class="ms-2 user-logo">
                                                    <img src="../assets/img/agent_lk_black.png" alt="">
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="user-logo">
                <div class="d-flex flex-column">
                    <p style="color: #343434;" class="my-2 text-center">Личный кабинет</p>
                    <div class="d-flex">
                        <a v-if="$store.state.user == true && $store.state.user_info.role=='user'" href="/lk">
                            <img src="../assets/img/guest_lk_black.png" alt="">
                        </a>
                        <a v-if="$store.state.user == false" href="/auth?role=user">
                            <img src="../assets/img/guest_lk_black.png" alt="">
                        </a>
                        <a v-if="$store.state.user == true && $store.state.user_info.role=='agent'" href="/lk_agent" class="ms-2">
                            <img src="../assets/img/agent_lk_black.png" alt="">
                        </a>
                        <a v-if="$store.state.user == false" href="/auth?role=agent" class="ms-2">
                            <img src="../assets/img/agent_lk_black.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isActive: false,
        }
    },
    methods: {
        toggleMenu() {
            this.isActive = !this.isActive;
        },
        openWebsite() {
            this.$router.push('/lk/').then(() => {
                window.scrollTo(0, 0);
            });
        }
    }
}
</script>